import React from "react";
import Drawer from "@material-ui/core/Drawer";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";

import {
  Button,
  colors,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { Link, LinkProps, NavLink } from "react-router-dom";
import { Logo } from "../logo/Logo";
import { categoriesRoutes } from "../../../routes";
import { useSelector, useDispatch } from "react-redux";
import {
  AuthenticationState,
  selectAuthenticate,
  signOut,
} from "../../../application/authentication/authentication_slice";
import { SizedBox } from "../components/sizedBox/sizedBox";

export interface SidemenuProps {
  width: number;
  backgroundColor: string;
  mobileOpen: boolean;
  toolBarColor?: string;
  menuItemColor?: string;
  handleDrawerToggle: VoidFunction;
}

export const Sidemenu: React.FC<SidemenuProps> = ({
  width = 240,
  backgroundColor = "blue",
  toolBarColor,
  menuItemColor = "white",
  mobileOpen = false,
  handleDrawerToggle,
}) => {
  const authentication = useSelector(selectAuthenticate);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        [theme.breakpoints.up("sm")]: {
          width: width,
          flexShrink: 0,
        },
      },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      logo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: toolBarColor ?? backgroundColor,
      },
      drawerPaper: {
        width: width,
        backgroundColor: backgroundColor,
      },
      ListItem: {
        color: menuItemColor,
      },
      category: {
        color: colors.grey.A400,
        fontWeight: "bolder",
      },
      navLink: {
        backgroundColor: "rgba(255, 255, 255, 0.32)",
        color: "black",
      },
    })
  );

  const classes = useStyles();
  const theme = useTheme();

  const drawer = (
    <div>
      <div className={[classes.toolbar, classes.logo].join(" ")}>
        <Logo size="medium"></Logo>
      </div>

      <Divider />
      <List>
        {categoriesRoutes.map((catRout, index) => {
          if (!catRout.isSideMenu) {
            return <div></div>;
          }
          var routes = catRout.routes.map((rout, index) => {
            var icon = rout.icon ? <rout.icon></rout.icon> : <div></div>;
            var exact = rout.path == "/";

            return (
              <ListItem
                color="white"
                exact={true}
                button
                component={NavLink}
                to={rout.path}
                className={classes.ListItem}
                activeClassName={classes.navLink}
              >
                <ListItemIcon className={classes.ListItem}>{icon}</ListItemIcon>
                <ListItemText primary={rout.name} color={"white"} />
              </ListItem>
            );
          });
          var cat = (
            <div key={catRout.name + "div"}>
              <ListItem className={classes.ListItem}>
                <ListItemText
                  primary={catRout.name}
                  className={classes.category}
                />
              </ListItem>
              {routes}
            </div>
          );
          return cat;
        })}
      </List>
      <Divider />
      <SizedBox height={10}></SizedBox>
      <Button variant="contained" onClick={() => dispatch(signOut())}>
        Sign out
      </Button>
    </div>
  );

  const content =
    authentication == AuthenticationState.Authenticated ? (
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    ) : (
      <div></div>
    );

  return <div>{content}</div>;
};
