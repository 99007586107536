import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
  Add,
  DashboardRounded,
  EditOutlined,
  Person,
  PersonAdd,
} from "@material-ui/icons";
import React, { ComponentClass } from "react";
import { Dashboard } from "./presentation/dashboard/dashboard";
import CreateDevice from "./presentation/device/createDevice/createDevice";
import CreateUser from "./presentation/user/createUser/createUser";
import { ManageUsers } from "./presentation/user/manageUsers/manageUsers";
import deviceData from "./presentation/user/deviceData/deviceData";
import UserDevices from "./presentation/user/userDevices/userDevices";
import EditUser from "./presentation/user/manageUser";

export interface Rout {
  isProtected?: boolean;
  path: string;
  name: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  component: any;
}

interface CategoryRout {
  routes: Rout[];
  name: string;
  isSideMenu?: boolean;
}

export const categoriesRoutes: CategoryRout[] = [
  {
    name: "",
    routes: [
      {
        path: "/",
        name: "Dashboard",
        icon: DashboardRounded,
        component: UserDevices,
      },
    ],
    isSideMenu: true,
  },
  {
    isSideMenu: false,
    name: "subdomains",
    routes: [
      {
        path: "/user/:id/devices",
        name: "user devices",
        component: UserDevices,
      },
      {
        path: "/device/:id",
        name: "device",
        component: deviceData,
      },
      {
        path: "/user/:id/edit",
        name: "edit user",
        component: EditUser,
      },
    ],
  },
];
