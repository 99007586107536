import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from "react-redux";
import {
  AuthenticationState,
  selectAuthenticate,
} from "../../../application/authentication/authentication_slice";
import { Logo } from "../logo/Logo";

export interface MyAppBarProps {
  backgroundColor: string;
  width: number;
  handleDrawerToggle: VoidFunction;
}

export const MyAppBar: React.FC<MyAppBarProps> = ({
  backgroundColor,
  width,
  handleDrawerToggle,
}) => {
  const authentication = useSelector(selectAuthenticate);


  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        [theme.breakpoints.up("sm")]: {
          width: `calc(100% - ${width}px)`,
          marginLeft: width,
        },
        backgroundColor: backgroundColor,
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      center:{
        margin:"auto"
      },
      toolbar: theme.mixins.toolbar,
    })
  );

  const classes = useStyles();

  const content =
    authentication == AuthenticationState.Authenticated ? (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.center}>
            <Logo size={"small"}></Logo>
          </div>
          {/* <Typography variant="h6" noWrap>
            Makaseb
          </Typography> */}
        </Toolbar>
      </AppBar>
    ) : (
      <div></div>
    );

  return <div>{content}</div>;
};
