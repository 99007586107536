import * as React from "react";
import {
  selectAuthenticate,
  AuthenticationState,
} from "../../application/authentication/authentication_slice";
import { useSelector } from "react-redux";
import { ChangeEvent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { client } from "../../core/network/network";
import { Storage } from "../../core/localStorage/localStorage";

interface IFirsTimePasswordProps {}

const FirsTimePassword: React.FunctionComponent<IFirsTimePasswordProps> = (
  props
) => {
  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        margin: "auto",
        marginInline: theme.spacing(5),
        width: "100%",
        height: "100%",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
        justifySelf: "center",
        padding: 40,
      },
      card: {
        padding: 40,
      },
      textField: {
        minWidth: "100px",
        marginInline: 20,
      },
    });
  });
  const classes = useStyles();

  const authentication = useSelector(selectAuthenticate);
  const history = useHistory();

  const [password, setPassword] = React.useState("");

  useEffect(() => {
    if (authentication == AuthenticationState.Unauthenticated) {
      history.push("/login");
    }
    const play = () => {};
  });

  const handlePasswordChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="h5">Please set your Password</Typography>
        <div className={classes.textField}>
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              var oldUserId = Storage.get("useru")["id"];
              try {
                var newuser = await client.get(
                  `users/${oldUserId}/setpassword?password=${password}`
                );
                Storage.set("useru", newuser["data"]["data"]);
                console.log(newuser["data"]["data"]);
                history.push("/");
              } catch (error) {
                console.log("error");
              }
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              hidden={true}
              type="password"
              onChange={handlePasswordChange}
              value={password}
            />
          </form>
        </div>
      </Card>
    </div>
  );
};

export default FirsTimePassword;
